<template>
  <div id="app">
    <Message />
  </div>
</template>

<script>
import Message from './Message.vue'

export default {
  name: 'App',
  components: {
    Message
  }
}
</script>

<style>
  
</style>
