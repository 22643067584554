import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import vuetify from './plugins/vuetify';
import VueSimpleAlert from "vue-simple-alert";
import VueBarcode from 'vue-barcode';

Vue.config.productionTip = false

Vue.prototype.$axios = axios;

Vue.component('barcode', VueBarcode)

Vue.use(VueSimpleAlert);

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
